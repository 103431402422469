import type { ReactNode } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const reCaptchaKey = import.meta.env.VITE_CAPTCHA_SITE_KEY;

export default function ReCaptchaProvider({ children }: { children: ReactNode }) {
	if (!reCaptchaKey) {
		console.warn('reCaptchaKey is not set');
		return children;
	}

	return <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>{children}</GoogleReCaptchaProvider>;
}
