import { Head } from '@inertiajs/react';
import type { ReactNode } from 'react';

export interface LayoutProps {
	children: ReactNode;
}

function Layout({ children }: LayoutProps) {
	return (
		<>
			<Head>
				<meta http-equiv="content-type" content="text/html; charset=UTF-8" />
				<meta http-equiv="X-UA-Compatible" content="IE=edge" />
				<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
				<meta name="format-detection" content="telephone=no" />

				<title>Merkur</title>
				<meta content="Merkur" name="application-name" />
				<meta content="Merkur" name="Description" />
				<meta content="Merkur" name="author" />

				<meta content="#ffffff" name="theme-color" />
				<meta content="#ffffff" name="msapplication-navbutton-color" />
				<meta content="#7cb03c" name="msapplication-TileColor" />
				<meta content="default" name="apple-mobile-web-app-status-bar-style" />
				<meta content="Merkur" name="apple-mobile-web-app-capable" />

				<meta content="" property="og:url" />
				<meta content="website" property="og:type" />
				<meta content="Merkur" property="og:title" />
				<meta content="Merkur" property="og:description" />
				<meta content="/og-image.jpg" property="og:image" />

				<meta name="twitter: url" content="" />
				<meta name="twitter: card" content="photo" />
				<meta name="twitter: site" content="website" />
				<meta name="twitter: title" content="Merkur" />
				<meta name="twitter: description" content="Merkur" />
				<meta name="twitter: image" content="/og-image.jpg" />

				<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
				<link href="/manifest.json" rel="manifest" />
			</Head>
			<main>{children}</main>
		</>
	);
}

export default Layout;
