import ReCaptchaProvider from '@provider/ReCaptchaProvider';
import ReactQueryProvider from '@provider/ReactQueryProvider';
import ToastProvider from '@provider/ToastProvider';
import type React from 'react';
import AppVersionProvider from './AppVersionProvider';

const compose = (providers: React.FC<{ children: React.ReactNode }>[]) =>
	providers.reduce((Prev, Curr) => ({ children }) => {
		const Prov = Prev ? (
			<Prev>
				<Curr>{children}</Curr>
			</Prev>
		) : (
			<Curr>{children}</Curr>
		);
		return Prov;
	});

const Providers = compose([ReactQueryProvider, ToastProvider, ReCaptchaProvider, AppVersionProvider]);

export default function Provider({ children }: { children?: React.ReactNode }) {
	return <Providers>{children}</Providers>;
}
