import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { ReactNode } from 'react';

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: 3,
			refetchOnMount: true,
			refetchOnWindowFocus: true,
			gcTime: 1000 * 60 * 60 * 24 * 14, // 14 days
			staleTime: 1000 * 60 * 5, // 5 minutes
		},
	},
});

export default function ReactQueryProvider({ children }: { children: ReactNode }) {
	return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}
