import { type ReactNode, useEffect, useMemo } from 'react';

export default function AppVersionProvider({ children }: { children: ReactNode }) {
	const data = useMemo(() => {
		const values = [
			{ name: 'date', value: new Date(import.meta.env.VITE_GIT_COMMIT_DATE).toLocaleString() },
			{ name: 'commit', value: import.meta.env.VITE_GIT_COMMIT_HASH },
			{ name: 'tag', value: import.meta.env.VITE_GIT_TAG },
		];

		return values.map(({ name, value }) => ({ name, value, [`data-${name}`]: value }));
	}, []);

	useEffect(() => {
		(window as any).appVersion = () => {
			// eslint-disable-next-line no-console
			console.table(data, ['name', 'value']);
			return data;
		};
	}, [data]);

	return (
		<>
			{children}
			<div className="pointer-events-none invisible absolute top-[300vh] left-[300vw] hidden" id="app-version">
				{data.map(({ name, value, ...attr }) => (
					<div key={name} {...attr}>
						{name.toUpperCase()}
						{value}
					</div>
				))}
			</div>
		</>
	);
}
