import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { createRoot } from 'react-dom/client';
import Layout from './components/Layout';
import Provider from './provider';
import '@core/i18n';

createInertiaApp({
	id: 'app',
	resolve: async (name) => {
		const page = ((await resolvePageComponent(`./routes/${name}.tsx`, import.meta.glob('./routes/**/*.tsx'))) as any).default;
		page.layout = page.layout || ((p: any) => <Layout>{p}</Layout>);
		return page;
	},
	setup({ el, App, props }) {
		const root = createRoot(el);

		root.render(
			<Provider>
				<App {...props} />
			</Provider>,
		);
	},
});
